import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { MouseEventHandler, ReactNode } from "react";
import RichText from "../../../elements/RichText/RichText";

export type ContentDProps = {
  title: string;
  children: ReactNode;
  onClick?: MouseEventHandler<Element>;
};

const ContentD = ({ title, children, onClick }: ContentDProps) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      sx={{
        minHeight: "120px",
        cursor: onClick && "pointer",
        position: "relative",
        "&:hover": {
          opacity: onClick && 0.6,
        },
      }}
    >
      {!isNil(children) && (
        <Grid
          item
          xs={6}
          sx={{
            position: "static",
            paddingRight: {
              xs: 2,
              sm: 3,
              md: 4,
            },
          }}
        >
          {children}
        </Grid>
      )}
      {!isNil(title) && (
        <Grid
          sx={{
            height: "100%",
            minHeight: "120px",
            borderLeft: (theme) => `5px solid ${theme.palette.secondary.main}`,
            paddingLeft: {
              xs: 2,
              sm: 3,
              md: 4,
            },
            paddingTop: {
              md: 4,
            },
            paddingBottom: {
              md: 4,
            },
          }}
          container
          item
          alignItems="center"
          xs={6}
        >
          <Grid
            item
            sx={{
              overflow: "hidden",
            }}
          >
            <RichText
              text={title}
              textProps={{
                marginBottom: 0,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ContentD;
