import { graphql, PageProps } from "gatsby";
import { isNil } from "lodash";
import PageTransitionWrapper from "../components/behaviors/PageTransitionWrapper/PageTransitionWrapper";
import CMSHeroA from "../components/cms/HeroA/CMSHeroA";
import CMSIntroText from "../components/cms/IntroText/CMSIntroText";
import CMSServices from "../components/cms/Services/CMSServices";
import PageSpacerWrapper from "../components/elements/PageSpacerWrapper/PageSpacerWrapper";
import PageWrapBreakout from "../components/modules/PageWrapBreakout/PageWrapBreakout";
import Seo from "../components/seo";

const Services = ({ data }: PageProps<GatsbyTypes.ServicesPageQuery>) => {
  const pageData = data?.prismicServicesPage?.data;

  return (
    <PageTransitionWrapper pageId="services-page">
      <Seo
        title={pageData?.seo_title}
        description={pageData?.seo_description}
      />
      <PageSpacerWrapper>
        {!isNil(pageData?.hero) && (
          <PageWrapBreakout>
            <CMSHeroA data={pageData.hero} />
          </PageWrapBreakout>
        )}
        {!isNil(pageData?.intro_text) && (
          <CMSIntroText data={pageData.intro_text} />
        )}
        {!isNil(pageData?.title) && !isNil(pageData?.services) && (
          <CMSServices
            title={data.prismicServicesPage.data.title}
            services={data.prismicServicesPage.data.services}
          />
        )}
      </PageSpacerWrapper>
    </PageTransitionWrapper>
  );
};

export default Services;

export const query = graphql`
  query ServicesPage {
    prismicServicesPage {
      data {
        hero {
          button_label
          button_link {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          mobile_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        intro_text {
          html
        }
        title {
          html
        }
        services {
          title1 {
            html
          }
          body {
            html
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          link_1 {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          link_image_1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          link_2 {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          link_image_2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          link_3 {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          link_image_3 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          link_4 {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          link_image_4 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          link_5 {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                id
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          link_image_5 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        seo_description
        seo_title
      }
    }
  }
`;
