import { Close } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { isNil } from "lodash";
import React, {
  useMemo,
  useCallback,
  useState,
  MouseEventHandler,
} from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import { buildLink } from "../../../utils/link/buildLink";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import Image from "../../elements/Image/Image";
import Link from "../../elements/Link/Link";
import RichText from "../../elements/RichText/RichText";
import ContentD from "../../views/Content/ContentD/ContentD";

type ServicesProps = {
  title: GatsbyTypes.ServicesPageQuery["prismicServicesPage"]["data"]["title"];
  services: GatsbyTypes.ServicesPageQuery["prismicServicesPage"]["data"]["services"];
};

type ServiceProps = {
  data: GatsbyTypes.ServicesPageQuery["prismicServicesPage"]["data"]["services"][0];
};

const Service = ({ data }: ServiceProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const builtData = useMemo(
    () => ({
      title: data?.title1?.html,
      body: data?.body?.html,
      image: buildImageData(data?.image),
      links: [
        {
          link: buildLink(data?.link_1),
          image: buildImageData(data?.link_image_1),
        },
        {
          link: buildLink(data?.link_2),
          image: buildImageData(data?.link_image_2),
        },
        {
          link: buildLink(data?.link_3),
          image: buildImageData(data?.link_image_3),
        },
        {
          link: buildLink(data?.link_4),
          image: buildImageData(data?.link_image_4),
        },
        {
          link: buildLink(data?.link_5),
          image: buildImageData(data?.link_image_5),
        },
      ],
    }),
    [
      data?.body.html,
      data?.image,
      data?.link_1,
      data?.link_2,
      data?.link_3,
      data?.link_4,
      data?.link_5,
      data?.link_image_1,
      data?.link_image_2,
      data?.link_image_3,
      data?.link_image_4,
      data?.link_image_5,
      data?.title1.html,
    ]
  );

  const toggleModal: MouseEventHandler<Element> = useCallback((e) => {
    e.stopPropagation();
    setModalOpen((prevState) => !prevState);
  }, []);

  if (isNil(builtData)) {
    return null;
  }

  return (
    <>
      {!isNil(builtData.image) && (
        <ContentD title={builtData.title} onClick={toggleModal}>
          {modalOpen && (
            <Close
              onClick={toggleModal}
              sx={{
                position: "absolute",
                top: 0,
                right: -2,
                display: "block",
                marginLeft: "auto",
                cursor: "pointer",
                "&:focus, &:hover": { transform: "scale(1.1)" },
                "&:active": { transform: "scale(1)" },
                outline: "none",
              }}
            />
          )}
          <Box sx={{ "& img": { maxHeight: 300 } }}>
            <Image image={builtData.image.image} alt={builtData.image.alt} />
          </Box>
        </ContentD>
      )}
      {modalOpen && (
        <Grid
          container
          rowSpacing={{ xs: 3, sm: 4 }}
          sx={{
            marginTop: {
              xs: 1,
            },
          }}
        >
          {!isNil(builtData.body) && (
            <Grid item>
              <RichText text={builtData.body} />
            </Grid>
          )}

          {!isNil(builtData.links) && (
            <Box
              sx={{
                width: "100%",
                bottom: 0,
                background: (theme) => theme.palette.common.white,
                py: 2,
              }}
            >
              {builtData.links.map(({ image, link }) => (
                <Link
                  sx={{
                    "& img": {
                      maxHeight: "45px",
                      width: "auto",
                    },
                    mr: 1,
                  }}
                  key={link}
                  href={link}
                >
                  <Image alt={image.alt} image={image.image} />
                </Link>
              ))}
            </Box>
          )}
        </Grid>
      )}
    </>
  );
};

const CMSServices = ({ title, services }: ServicesProps) => {
  return (
    <Grid container rowSpacing={{ xs: 2, sm: 3 }}>
      {!isNil(title) && (
        <InViewAnimationGrid item>
          <RichText text={title.html} />
        </InViewAnimationGrid>
      )}

      <Grid item container rowSpacing={{ xs: 3, sm: 4, md: 8 }}>
        {services.map((service) => (
          <InViewAnimationGrid item xs={12} key={service.title1.html}>
            <Service data={service} />
          </InViewAnimationGrid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CMSServices;
